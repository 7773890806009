<template>
    <div>
        <div class="flex flex-column pt-4">
            <div class="row justify-content-end mr-0">
                <div class="col-3 px-1">
                    <FloatLabel>
                        <Dropdown v-model="selectFilterOption" id="selectFilterOption" :options="selectFilterOptions"
                            option-label="name" class="w-100" v-on:change="onFilterDropdownChange" />
                        <label for="selectFilterOption">ตัวเลือกการกรอง</label>
                    </FloatLabel>
                </div>
                <div class="col-auto px-1" v-if="isDatePickerShow">
                    <FloatLabel>
                        <Calendar v-model="filterStartDate" id="filterStartDate" />
                        <label for="filterStartDate">วันที่เริ่มต้น</label>
                    </FloatLabel>
                </div>
                <div class="col-auto px-1" v-if="isDatePickerShow">
                    <FloatLabel>
                        <Calendar v-model="filterEndDate" id="filterEndDate" />
                        <label for="filterEndDate">วันที่สิ้นสุด</label>
                    </FloatLabel>
                </div>
                <div class="col-auto px-1" v-if="isDatePickerShow">
                    <Button class="btn btn-outline-dark" style="height: 2.7rem;" @click="filter" label="กรองข้อมูล" />
                </div>
                <div class="col-auto pl-1 pr-1">
                    <Button class="btn btn-outline-dark" style="height: 2.7rem;" @click="exportChart"
                        label="นำออกข้อมูล" />
                </div>
            </div>
        </div>
        <div class="flex flex-column pt-2">
            <div class="row">
                <div class="col-3 p-3">
                    <Card>
                        <template #title>เคสใหม่ (ภายใน 30 วัน)</template>
                        <template #content>
                            <h4 class="m-1 text-right text-warning">
                                {{ newCase }}
                            </h4>
                        </template>
                    </Card>
                </div>
                <div class="col-3 p-3">
                    <Card>
                        <template #title>เคสที่เปิดอยู่</template>
                        <template #content>
                            <h4 class="m-1 text-right text-danger">
                                {{ openCase }}
                            </h4>
                        </template>
                    </Card>
                </div>
                <div class="col-3 p-3">
                    <Card>
                        <template #title>เคสที่ปิดแล้ว</template>
                        <template #content>
                            <h4 class="m-1 text-right text-success">
                                {{ closedCase }}
                            </h4>
                        </template>
                    </Card>
                </div>
                <div class="col-3 p-3">
                    <Card>
                        <template #title>เตสทั้งหมด</template>
                        <template #content>
                            <h4 class="m-1 text-right text-secondary">
                                {{ allCase }}
                            </h4>
                        </template>
                    </Card>
                </div>
                <div class="col-4">
                    <Chart class="responsive-canvas" type="pie" :data="pieChartData" :options="pieChartOptions" />
                </div>
                <div class="col-8">
                    <Chart class="responsive-canvas" type="bar" :data="barChartData" :options="barChartOptions"
                        style="width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js" setup>
import { ref, onMounted } from "vue";
// eslint-disable-next-line
import { capitalize, getTimeFilter, instance } from '@/App.vue';
import FloatLabel from "primevue/floatlabel";
// eslint-disable-next-line
import { decodeUnicodeEscapeSequences, exportBlob, fetchAndExport } from "@/utils/utils";

onMounted(() => {
    fetchData();
    pieChartOptions.value = setPieChartOptions("จำนวนเคสตามรูปแบบภัยคุกคาม");
    barChartOptions.value = setBarChartOptions("จำนวนเคสตามประเภทหน่วยงาน");
});

var selectFilterOption = ref({ name: "ทั้งหมด" });
var filterStartDate = ref();
var filterEndDate = ref();
var newCase = ref();
var openCase = ref();
var closedCase = ref();
var allCase = ref();

const pieChartData = ref();
const barChartData = ref();
const pieChartOptions = ref();
const barChartOptions = ref();
const selectFilterOptions = ref([{ name: "ทั้งหมด" }, { name: "วัน" }, { name: "สัปดาห์" }, { name: "เดือน" }, { name: "ปี" }, { name: "เลือกเอง" }])

var isDatePickerShow = ref(false);

function fetchData() {
    instance.get("/scam/dashboard", {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    }).then(dashboard_res => {
        newCase.value = dashboard_res.data.newCase;
        openCase.value = dashboard_res.data.openCase;
        closedCase.value = dashboard_res.data.closedCase;
        allCase.value = dashboard_res.data.allCase;

        pieChartData.value = setCaseTypeData(dashboard_res.data.caseType);
        barChartData.value = setDepartmentCaseData(dashboard_res.data.departmentCase);
    }).catch(dashboard_err => {
        console.log(dashboard_err);
    });
}

// eslint-disable-next-line
function exportChart(event) {
    var timeFilter = dashboardFilterQueryTimeString();
    var dateStrings = timeFilter.split('&');
    var start = '';
    var end = ''
    if (dateStrings.length == 2) {
        start = dateStrings[1].replace('start=', '');
    } else if (dateStrings.length == 3) {
        start = dateStrings[1].replace('start=', '');
        end = dateStrings[2].replace('end=', '_');
    }

    instance.get(`/scam/download_threats_category_chart?${timeFilter}`,
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` },
            responseType: 'blob'
        }
    ).then(threats_category_chart_res => {
        const data = threats_category_chart_res.data;
        const mimeType = 'image/png';
        const fileName = `threats_category_chart-${start}${end}.png`;
        exportBlob(data, fileName, mimeType);
    }).catch(threats_category_chart_err => {
        console.log("[threats category chart err]", threats_category_chart_err);
    });

    instance.get(`/scam/download_deparment_chart?${timeFilter}`,
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` },
            responseType: 'blob'
        }
    ).then(threats_category_chart_res => {

        const data = threats_category_chart_res.data;
        const mimeType = 'image/png';
        const fileName = `department_chart-${start}${end}.png`;
        exportBlob(data, fileName, mimeType);
    }).catch(threats_category_chart_err => {
        console.log("[threats category chart err]", threats_category_chart_err);
    });
}

function onFilterDropdownChange(event) {
    isDatePickerShow.value = (event.value.name == "เลือกเอง");
    if (event.value.name != "เลือกเอง") {
        filter();
    }
}

function setCaseTypeData(caseTypes) {
    var threats_categories = [];
    var counts = [];
    for (var i = 0; i < caseTypes.length; i++) {
        threats_categories[i] = capitalize(caseTypes[i].threats_category);
        counts[i] = caseTypes[i].count;
    }
    const documentStyle = getComputedStyle(document.body);
    return {
        labels: threats_categories,
        datasets: [
            {
                data: counts,
                backgroundColor: [documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--gray-500')],
                hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')]
            }
        ]
    };
}


function setDepartmentCaseData(departmentCase) {
    var departments = [];
    var counts = [];
    for (var i = 0; i < departmentCase.length; i++) {
        departments[i] = capitalize(departmentCase[i].department);
        counts[i] = departmentCase[i].count;
    }
    const documentStyle = getComputedStyle(document.body);
    return {
        labels: departments,
        datasets: [
            {
                label: 'Department Case',
                data: counts,
                backgroundColor: [documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--gray-500')],
                hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')]
            }
        ]
    };
}

function setPieChartOptions(chartName) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    color: textColor
                },
            },
            title: {
                display: true,
                text: chartName ?? 'Chart.js Pie Chart'
            },
        }
    };
}

function setBarChartOptions(chartName) {
    return {
        responsive: true,
        plugins: {
            legend: false,
            title: {
                display: true,
                text: chartName ?? 'Chart.js Pie Chart'
            },
        }
    };
}


/**
 * return dashboard filter URL query time string
 */
function dashboardFilterQueryTimeString() {
    const currentDate = new Date();
    var startDate = new Date(currentDate);

    var timeFilter = "";
    if (selectFilterOption.value.name == "เลือกเอง") {
        timeFilter = getTimeFilter(filterStartDate.value, filterEndDate.value);
    } else if (selectFilterOption.value.name == "วัน") {
        timeFilter = getTimeFilter(currentDate);
    } else if (selectFilterOption.value.name == "สัปดาห์") {
        startDate.setDate(currentDate.getDate() - 7);
        timeFilter = getTimeFilter(startDate, currentDate);
    } else if (selectFilterOption.value.name == "เดือน") {
        startDate.setDate(currentDate.getDate() - 30);
        timeFilter = getTimeFilter(startDate, currentDate);
    } else if (selectFilterOption.value.name == "ปี") {
        timeFilter = getTimeFilter(new Date(currentDate.getFullYear(), 0, 1), currentDate);
    }

    return timeFilter;
}

function filter() {
    var timeFilter = dashboardFilterQueryTimeString();

    instance.get(`/scam/dashboard?${timeFilter}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    }).then(filter_res => {
        newCase.value = filter_res.data.newCase;
        openCase.value = filter_res.data.openCase;
        closedCase.value = filter_res.data.closedCase;
        allCase.value = filter_res.data.allCase;

        pieChartData.value = setCaseTypeData(filter_res.data.caseType);
        barChartData.value = setDepartmentCaseData(filter_res.data.departmentCase);
    }).catch(filter_err => {
        console.log("[filter err]", filter_err);
    });
}
</script>
<style>
.p-float-label {
    margin-left: 8px;
}

canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>@/utils/utils