<template>
  <Toast />
  <div class="" style="margin-top: calc(20vh)">
    <div class="row justify-content-center">
      <div class="col-11 col-sm-8 col-md-6 col-xl-3 justify-content-center">
        <div class="card">
          <div class="card-header">
            <h3 class="text-center">SOC App Login</h3>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleLogin">
              <div class=" mt-3 pt-3">
                <FloatLabel>
                  <InputText type="text" class="form-control" id="username" v-model="username" :invalid="isUserValid" />
                  <label for="username">ชื่อผู้ใช้</label>
                </FloatLabel>
              </div>
              <div class=" mt-3 pt-3">
                <FloatLabel>
                  <Password v-model="password" :feedback="false" id="password" toggle-mask="" :invalid="isPasswordValid"
                    class=" w-100" />
                  <label for="password">รหัสผ่าน</label>
                </FloatLabel>
              </div>
              <div style="text-align: center;">
                <button type="submit" class="btn btn-primary btn-block mt-4 w-100">เข้าสู่ระบบ</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { instance } from '@/App.vue';
import router from '@/router';

import InputText from 'primevue/inputtext';
import Password from 'primevue/password';

import { useToast } from 'primevue/usetoast';

const toast = useToast();

var isUserValid = false;
var isPasswordValid = false;
var username = ref("");
var password = ref("");


onMounted(() => {
  if (localStorage.length > 0) {
    localStorage.clear();
  }
});


function handleLogin() {
  auth(username.value, password.value);
}

function auth(username, password) {
  var ip = "/auth/login";
  var data = {
    "username": username,
    "password": password
  };

  instance.post(ip, data).then(function (response) {
    localStorage.removeItem("soc_token");
    localStorage.removeItem("soc_user");
    localStorage.removeItem("soc_role");
    localStorage.removeItem("soc_user_id");
    localStorage.removeItem("two-authentication");
    localStorage.removeItem("twofa");
    localStorage.removeItem("secret");
    localStorage.setItem("soc_user", username);
    localStorage.setItem("soc_token", response.data.access_token);
    localStorage.setItem("soc_role", response.data.role);
    localStorage.setItem("soc_user_id", response.data.user);
    localStorage.setItem("two-authentication", response.data.qrCodeData);
    localStorage.setItem("twofa", response.data.twofa);
    localStorage.setItem("secret", response.data.secret);
    // router.push("/dashboard");
    router.push("/two-authentication");
  }).catch(function (auth_error) {
    console.log(`auth_error: ${auth_error}`);
    console.log(auth_error);
    if (auth_error.message == "Network Error") {
      toast.add({
        severity: 'error', summary: 'Fail', detail: auth_error.message, life: 3000
      });
    } else if (auth_error.message.includes("timeout")) {
      toast.add({
        severity: 'error', summary: 'Fail', detail: 'Timeout', life: 3000
      });
    }
    else {
      toast.add({
        severity: 'error', summary: 'Fail', detail: 'ชื่อผู้ใช้ or password incorrect.', life: 3000
      });
    }
  });
}
</script>

<style>
body {
  width: 100%;
}
</style>
