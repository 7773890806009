/* eslint-disable */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import Button from 'primevue/button';
import Card from 'primevue/card';
import Calendar from 'primevue/calendar';
import Chart from 'primevue/chart';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FloatLabel from 'primevue/floatlabel';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import InputIcon from 'primevue/inputicon';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast';
import ScrollPanel from 'primevue/scrollpanel';


import {nextTick} from 'vue';

const DEFAULT_TITLE = "Vue SOC Application";
router.afterEach((to) => {
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(PrimeVue, {
    // unstyled: false,
    ripple: true,
});
app.component('Button', Button);
app.component('Card', Card);
app.component('Calendar', Calendar);
app.component('Chart', Chart);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FloatLabel', FloatLabel);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('InputText', InputText);
app.component('OverlayPanel', OverlayPanel);
app.component('ScrollPanel', ScrollPanel);
app.component('Toast', Toast);
app.use(ConfirmationService);
app.use(ToastService);
// app.component('Button', Button);

app.mount("#app");
