<template>
    <Toast />
    <form @submit.prevent="editCase">
        <div class="flex flex-column justify-content-end topbar">
            <div class="row">
                <div class="col"></div>
                <div class="col-auto p-0">
                    <Button :class='btnDynamicClass(isDisabledCloseButton(), "btn btn-outline-danger")'
                        @click="closeCase" :hidden="isDisabledCloseButton()">
                        ปิดเคส
                    </Button>
                </div>
                <div class="col-auto p-0">
                    <Button :class='btnDynamicClass(isDisabledCloseButton(), "btn btn-outline-primary")'
                        @click="checkCaseID" :hidden="isDisabledCloseButton()">
                        อัพเดทวันที่ตรวจเคส
                    </Button>
                </div>
                <div class="col-auto p-0">
                    <Button :class='btnDynamicClass(isDisabledCloseButton(), "btn btn-outline-dark")' @click="checkURL"
                        :hidden="isDisabledCloseButton()">
                        ตรวจสถานะลิงก์
                    </Button>
                </div>
                <div class="col-auto p-0">
                    <Button :class='btnDynamicClass(isDisabledCloseButton(), "btn btn-outline-dark")'
                        @click="duplicateCase">
                        ทำซ้ำเอกสาร
                    </Button>
                </div>
                <div class="col-auto p-0">
                    <Button type="submit" class="btn btn-success" :hidden="isDisabledCloseButton()">
                        บันทึกข้อมูล
                    </Button>
                </div>
            </div>
        </div>

        <transition-group name="p-message" tag="div">
            <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
        </transition-group>

        <div>
            <div class="flex flex-column">
                <div class="row">
                    <div class="col-5 mt-2 pt-2">
                        <label for="caseStatus">สถานะของลิงก์</label>
                        <InputText v-if="caseStatus == 1" v-model="caseStatus" value="เปิด" id="caseStatus"
                            class="w-100" disabled style="background-color: green; color: white; font-weight: 600;" />
                        <InputText v-else v-model="caseStatus" value="ปิด" id="caseStatus" class="w-100" disabled
                            style="background-color: var(--status-closed-red); color: white; font-weight: 600;" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="lastCheckTime">วันที่เช็คสถานะของลิงก์</label>
                        <InputText class="w-100" id="lastCheckTime" v-model="lastCheckTime" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="phishingUrl">Phishing URL</label>
                        <InputText class="w-100" id="phishingUrl" v-model="phishingUrl" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="phishingUrlEncode">Phishing URL Encode</label>
                        <InputText class="w-100" id="phishingUrlEncode" v-model="phishingUrlEncode" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="lineUrl">Link Line</label>
                        <InputText class="w-100" id="lineUrl" v-model="lineUrl" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="domain">ชื่อ Domain</label>
                        <InputText class="w-100" id="domain" v-model="domain" />
                    </div>
                    <div class="col-5 text-start mt-2 pt-2">
                        <label for="threatsCategory">รูปแบบภัยคุกคาม</label>
                        <Dropdown v-model="threatsCategory" id="threatsCategory" :options="threats" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-2 pt-2">
                        <label for="incidentClass">Incident Class</label>
                        <Dropdown v-model="incidentClass" id="incidentClass" :options="incidentClasses"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-2 pt-2">
                        <label for="counterMeasure">การปฏิบัติในการรับมือ</label>
                        <Dropdown v-model="counterMeasure" id="counterMeasure" :options="counterMeasures"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-2 pt-2">
                        <label for="filterStartDate">วัน/ เวลา สถานที่เกิดเหตุ</label>
                        <Calendar v-model="incidentTime" :value="formatDateTime(incidentTime)" style="width: 100%;"
                            panelStyle="width:50%" id="incidentTime" dateFormat="dd/mm/yyyy" :show-time="false"
                            hour-format="">
                            <template #body="slotProps">
                                <div>
                                    {{ slotProps.data }}
                                </div>
                            </template>
                        </Calendar>
                    </div>

                    <Divider class="mt-3 pt-3" />
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamSystemName">ชื่อระบบ/เว็บไซต์</label>
                        <InputText class="w-100" id="scamSystemName" v-model="scamSystemName" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamDepartmentName">ขื่อหน่วยงาน</label>
                        <InputText class="w-100" id="scamDepartmentName" v-model="scamDepartmentName" />
                    </div>

                    <Divider class="mt-3 pt-3" />
                    <div class="col-5 mt-2 pt-2">
                        <label for="department">ประเภทหน่วยงาน</label>
                        <Dropdown v-model="department" id="department" :options="departments" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="departmentNumber">เลขกำกับหน่วยงาน</label>
                        <Dropdown v-model="departmentNumber" id="departmentNumber" :options="departmentNumbers"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="issuedBy">ฝ่ายที่ออกเคส</label>
                        <Dropdown v-model="issuedBy" id="issuedBy" :options="issuedBies" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="issuedYear">ปีที่ออกเคส</label>
                        <InputText v-model="issuedYear" id="issuedYear" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamCaseNo">Case Ref No</label>
                        <InputText v-model="scamCaseNo" id="scamCaseNo" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamType">ประเภทเคส</label>
                        <Dropdown v-model="scamType" id="scamType" :options="scamTypes" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamTypeNumber">รหัสประเภท</label>
                        <Dropdown v-model="scamTypeNumber" id="scamTypeNumber" :options="scamTypeNumbers"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="investigateSolution">การปฏิบัติการในการค้นหาเคส</label>
                        <Dropdown v-model="investigateSolution" id="investigateSolution" :options="investigateSolutions"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="region">ภูมิภาค</label>
                        <Dropdown v-model="region" id="region" :options="regions" optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="province">จังหวัด</label>
                        <Dropdown v-model="province" id="province" :options="provinces" optionLabel="name" filter
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="majorAffiliation">สังกัดใหญ่</label>
                        <InputText class="w-100" id="majorAffiliation" v-model="majorAffiliation" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="subAffiliation">สังกัดย่อย</label>
                        <InputText class="w-100" id="subAffiliation" v-model="subAffiliation" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="informedChannel">รับแจ้ง</label>
                        <Dropdown v-model="informedChannel" id="informedChannel" :options="informedChannels"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="informerName">ชื่อผู้แจ้ง</label>
                        <InputText class="w-100" id="informerName" v-model="informerName" />
                    </div>

                    <Divider class=" mt-3 pt-3" />
                    <h5 class="col-5 ml-3 pl-3" style="padding-left: 24px !important;">
                        Whois
                    </h5>
                    <div class="col-5 text-right">
                        <Button :class='btnDynamicClass(isDisabledCloseButton(), "btn btn-outline-primary")'
                            @click="syncWhois" :hidden="isDisabledCloseButton()">
                            Sync
                        </Button>
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisCreatedDate">Whois Created Date</label>
                        <InputText class="w-100" id="whoisCreatedDate" v-model="whoisCreatedDate"
                            :value="formatDateTime(whoisCreatedDate)" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisExpireDate">Whois Expire Date</label>
                        <InputText class="w-100" id="whoisExpireDate" v-model="whoisExpireDate"
                            :value="formatDateTime(whoisExpireDate)" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisUpdatedDate">Whois Updated Date</label>
                        <InputText class="w-100" id="whoisUpdatedDate" v-model="whoisUpdatedDate"
                            :value="formatDateTime(whoisUpdatedDate)" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisNameServer">Whois Name Server</label>
                        <InputText class="w-100" id="whoisNameServer" v-model="whoisNameServer" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisIpAddress">Whois IP Address</label>
                        <InputText class="w-100" id="whoisIpAddress" v-model="whoisIpAddress" disabled />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="whoisAsn">Whois ASN</label>
                        <InputText class="w-100" id="whoisAsn" v-model="whoisAsn" disabled />
                    </div>
                </div>
            </div>
            <Button type="submit" class="btn btn-success btn-block flex-end  mt-4"
                :hidden="isDisabledCloseButton">Update</Button>
        </div>
    </form>
</template>
<script lang="js" setup>
import threatsData from '../../assets/json/threats.json';
import incidentClassesData from '../../assets/json/incidentClasses.json';
import counterMeasuresData from '../../assets/json/counterMeasures.json';
import departmentsData from '../../assets/json/departments.json';
import departmentNumbersData from '../../assets/json/departmentNumbers.json';
import issuedBiesData from '../../assets/json/issuedBies.json';
import investigateSolutionsData from '../../assets/json/investigateSolutions.json';
import scamTypesData from '../../assets/json/scamTypes.json';
import regionsData from '../../assets/json/regions.json';
import informedChannelsData from '../../assets/json/informedChannels.json';
import scamTypeNumbersData from '../../assets/json/scamTypeNumbers.json';
import provincesData from '../../assets/json/provinces.json';

import { ref, onMounted } from 'vue';
import { instance, formatDateTime } from '@/App.vue';
import router from '@/router';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import Divider from 'primevue/divider';
import Message from 'primevue/message';
import { useToast } from 'primevue/usetoast';

// top message 
const messages = ref([]);
var messagesId = 0;

const isDisabledCloseButton = () => {
    // return (closedAt.value != null && closedAt.value != "");
    return (caseStatus.value == 0);
};

/**
 * 
 * @param {booleam} isDisabled 
 * @param {string} enabledCssClass - CSS class string
 * @example
 *  isDisabled => true
 *  enabledCssClass => btn btn-outline-primary
 */
function btnDynamicClass(isDisabled, enabledCssClass) {
    if (isDisabled) {
        return "btn btn-outline-secondary";
    } else {
        return enabledCssClass ?? "btn btn-outline-dark";
    }
}

const toast = useToast();

function fetchData(fetchDataRouter) {
    var id = fetchDataRouter.currentRoute.value.params.id;
    caseId.value = id;

    instance.get(`/scam/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    }).then(case_res => {
        caseId.value = case_res.data.id;
        userId.value = case_res.data.userId;
        phishingUrl.value = case_res.data.phishingUrl;
        phishingUrlEncode.value = case_res.data.phishingUrlEncode;
        threatsCategory.value = { name: case_res.data.threatsCategory };
        incidentClass.value = { name: case_res.data.incidentClass };
        counterMeasure.value = { name: case_res.data.countermeasure };
        incidentTime.value = formatDateTime(case_res.data.incidentTime),
            department.value = { name: case_res.data.department };
        departmentNumber.value = { name: case_res.data.departmentNumber };
        issuedBy.value = { name: case_res.data.issuedBy };
        issuedYear.value = case_res.data.issuedYear;
        investigateSolution.value = { name: case_res.data.investigateSolution };
        scamType.value = { name: case_res.data.scamType };
        scamSystemName.value = case_res.data.scamSystemName;
        scamDepartmentName.value = case_res.data.scamDepartmentName;
        region.value = { name: case_res.data.region };
        province.value = { name: case_res.data.province };
        domain.value = case_res.data.domain;
        majorAffiliation.value = case_res.data.majorAffiliation;
        subAffiliation.value = case_res.data.subAffiliation;
        scamCaseNo.value = case_res.data.scamCaseNo;
        scamTypeNumber.value = { name: case_res.data.scamTypeNumber };
        informedChannel.value = { name: case_res.data.informedChanel };
        informerName.value = case_res.data.informerName;
        lineUrl.value = case_res.data.lineUrl;
        lastCheckTime.value = (case_res.data.lastCheckTime != null) ? case_res.data.lastCheckTime.replace("T", " ").replace("Z", "") : case_res.data.lastCheckTime;
        whoisCreatedDate.value = case_res.data.whoisCreatedDate;
        whoisExpireDate.value = case_res.data.whoisExpireDate;
        whoisUpdatedDate.value = case_res.data.whoisUpdatedDate;
        whoisNameServer.value = case_res.data.whoisNameServer;
        whoisIpAddress.value = case_res.data.whoisIpAddress;
        whoisAsn.value = case_res.data.whoisAsn;
        closedAt.value = case_res.data.closedAt;
        updatedAt.value = case_res.data.updatedAt;
        caseStatus.value = case_res.data.status;

    }).catch(case_err => {
        console.log("case_err");
        console.log(case_err);
    });
}


onMounted(() => {
    fetchData(router);
});

// Data
var caseId = ref("");
var userId = ref("");
var phishingUrl = ref("");
var phishingUrlEncode = ref("");
var threatsCategory = ref({});
var incidentClass = ref({});
var counterMeasure = ref({});
var department = ref({});
var departmentNumber = ref({});
var issuedBy = ref({});
var issuedYear = ref("");
var investigateSolution = ref({});
var scamCaseNo = ref();
var scamType = ref({});
var scamSystemName = ref("");
var scamDepartmentName = ref("");
var region = ref({});
var province = ref({});
var domain = ref("");
var majorAffiliation = ref("");
var subAffiliation = ref("");
var scamTypeNumber = ref({});
var informedChannel = ref({});
var informerName = ref("");
var lineUrl = ref("");
var caseStatus = ref("");
var lastCheckTime = ref("");
var whoisCreatedDate = ref("");
var whoisExpireDate = ref("");
var whoisUpdatedDate = ref("");
var whoisNameServer = ref("");
var whoisIpAddress = ref("");
var whoisAsn = ref("");
var closedAt = ref("");
var updatedAt = ref("");
var incidentTime = ref(null);

// Option List
var threats = ref(threatsData);
var incidentClasses = ref(incidentClassesData);
var counterMeasures = ref(counterMeasuresData);
var departments = ref(departmentsData);
var departmentNumbers = ref(departmentNumbersData);
var issuedBies = ref(issuedBiesData);
var investigateSolutions = ref(investigateSolutionsData);
var scamTypes = ref(scamTypesData);
var regions = ref(regionsData);
var provinces = ref(provincesData);
var scamTypeNumbers = ref(scamTypeNumbersData);
var informedChannels = ref(informedChannelsData);

function editCase() {
    // var data = {
    //     "threatsCategory": lowerCase(threatsCategory.value.name),
    //     "incidentClass": lowerCase(incidentClass.value.name),
    //     "countermeasure": lowerCase(counterMeasure.value.name) ?? "b",
    //     "department": lowerCase(department.value.name),
    //     "departmentNumber": lowerCase(departmentNumber.value.name),
    //     "issuedBy": lowerCase(issuedBy.value.name),
    //     "issuedYear": issuedYear.value,
    //     "investigateSolution": lowerCase(investigateSolution.value.name) ?? "",
    //     "scamType": lowerCase(scamType.value.name) ?? "",
    //     "scamSystemName": scamSystemName.value,
    //     "scamDepartmentName": scamDepartmentName.value,
    //     "region": lowerCase(region.value.name),
    //     "province": lowerCase(province.value.name),
    //     "domain": domain.value,
    //     "majorAffiliation": majorAffiliation.value,
    //     "subAffiliation": subAffiliation.value,
    //     "scamCaseNo": scamCaseNo.value,
    //     "scamTypeNumber": lowerCase(scamTypeNumbers.value.name) ?? "",
    //     "informedChanel": lowerCase(informedChannel.value.name) ?? "",
    //     "informerName": informerName.value,
    //     "phishingUrl": phishingUrl.value,
    //     "phishingUrlEncode": phishingUrlEncode.value,
    //     "lineUrl": lineUrl.value,
    // };
    var data = {
        "threatsCategory": threatsCategory.value.name,
        "incidentClass": incidentClass.value.name,
        "countermeasure": counterMeasure.value.name ?? "b",
        "incidentTime": incidentTime.value,
        "department": department.value.name,
        "departmentNumber": departmentNumber.value.name,
        "issuedBy": issuedBy.value.name,
        "issuedYear": issuedYear.value,
        "investigateSolution": investigateSolution.value.name ?? "",
        "scamType": scamType.value.name ?? "",
        "scamSystemName": scamSystemName.value,
        "scamDepartmentName": scamDepartmentName.value,
        "region": region.value.name,
        "province": province.value.name,
        "domain": domain.value,
        "majorAffiliation": majorAffiliation.value,
        "subAffiliation": subAffiliation.value,
        "scamCaseNo": scamCaseNo.value,
        "scamTypeNumber": scamTypeNumber.value.name ?? "",
        "informedChanel": informedChannel.value.name ?? "",
        "informerName": informerName.value,
        "phishingUrl": phishingUrl.value,
        "phishingUrlEncode": phishingUrlEncode.value,
        "lineUrl": lineUrl.value,
    };

    var headerConfig = {
        headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
    };
    // eslint-disable-next-line
    instance.patch(`/scam/${caseId.value}`, data, headerConfig).then(edit_case_response => {
        router.replace("/case");
    }).catch(edit_case_err => {
        console.log("[Edit Case]: Fail");
        console.log("edit_case_err", edit_case_err);
        messages.value = [
            { severity: 'error', content: 'Edit fail', id: messagesId++ },
        ];
    });
}


function duplicateCase() {
    console.log("[duplicate case]");
    router.push({ path: "/case/new", query: { from: caseId.value } });
}

function closeCase() {
    const url = `/scam/cloes_scam_case/${caseId.value}`;
    instance.get(url,
        { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
    )
        // eslint-disable-next-line
        .then(closeCase_res => {
            toast.add({
                severity: 'success', summary: 'Success', detail: `ปิดเคส ${caseId.value}: Complete`, life: 3000
            });
            fetchData(router);
        }).catch(closeCase_err => {
            console.log(`[ปิดเคส ${caseId.value}] Fail`, closeCase_err);
        })
}

function checkCaseID() {
    const url = `/scam/checked_scam/${caseId.value}`;
    instance.get(url,
        { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
    )// eslint-disable-next-line
        .then(checkCase_res => {
            toast.add({
                severity: 'info', summary: 'Success', detail: `Case [${caseId.value}] is updated. `, life: 3000
            });
            fetchData(router);
        }).catch(checkCase_err => {
            console.log("[check case error]", checkCase_err);
        })
}

// function updateWhoIs() {
//     if (lineUrl.value == null || lineUrl.value == "") {
//         instance.get(`/scam/check_url/${caseId.value}`,
//             { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
//         )
//          // eslint-disable-next-line
//          .then(check_res => {
//         toast.add({
//             severity: 'info', summary: 'Success', detail: `Check case ${caseId.value}: Complete`, life: 3000
//         });
//             fetchData(router);
//         }).catch(check_error => {
//             console.log(`[check url ${caseId.value}] Fail`, check_error);
//         });
//     } else {
//         console.log(`[check url ${caseId.value}] Fail`, "Line Link can't check.");
//     }
// }

function checkURL() {
    // if (lineUrl.value == null || lineUrl.value == "") {
    instance.get(`/scam/check_url/${caseId.value}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
    ).then(check_res => {
        console.log(`[check url ${caseId.value}] Complete`, check_res);
        toast.add({
            severity: 'info', summary: 'Success', detail: `Check case ${caseId.value}: Complete`, life: 3000
        });
        fetchData(router);
    }).catch(check_error => {
        console.log(`[check url ${caseId.value}] Fail`, check_error);
    });
    // } else {
    //     console.log(`[check url ${caseId.value}] Fail`, "Line Link can't check.");
    // }
}

function syncWhois() {
    var header = {
        headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
    };
    // eslint-disable-next-line
    instance.get(`/scam/update_whois/${caseId.value}`, header).then(update_res => {
        toast.add({
            severity: 'info', summary: 'Success', detail: `Sync with WHOIS service is completed. `, life: 3000
        });
        fetchData(router);
    }).catch(update_error => {
        console.log("[update_whois] Fail");
        console.log(update_error);
    });
}
</script>

<style>
/* .topbar {
    width: 83% !important;
} */
</style>