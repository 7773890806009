<template>
    <Toast />
    <form @submit.prevent="saveNewUser">
        <transition-group name="p-message" tag="div">
            <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
        </transition-group>

        <div>
            <div class="flex flex-column">
                <div class="row">
                    <h5 class="col-12 mt-2 pt-2">
                        ข้อมูลบัญชี
                    </h5>
                    <div class="col-5 mt-2 pt-2">
                        <label for="username">ชื่อผู้ใช้ <span class="required">*</span></label>
                        <InputText class="w-100" id="username" v-model="username" />
                    </div>
                    <div class="col-5 mt-2 pt-2 ">
                        <label for="password">รหัสผ่าน <span class="required">*</span></label>
                        <!-- <Password v-model="password" id="password" toggle-mask :feedback="false" style="width: 100% !important;" /> -->
                        <Password class="w-100" v-model="password" id="password" toggle-mask>
                            <template #header>
                                <h5>กรอกรหัสผ่าน</h5>
                            </template>
                            <template #footer>
                                <Divider />
                                <div>
                                    <h5 class="pl-2 ml-2 mt-2">คำแนะนำในการสร้างรหัสผ่าน</h5>
                                    <ul class="mt-0" style="padding-left: 1.5rem; line-height: 1.5">
                                        <li>ตัวอักษรอย่างน้อย 8 ตัว</li>
                                        <li>ตัวพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว</li>
                                        <li>ตัวพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว</li>
                                        <li>ตัวเลข (0-9) อย่างน้อย 1 ตัว</li>
                                    </ul>
                                </div>
                            </template>
                        </Password>
                    </div>
                    <Divider class="mt-3 pt-3" />
                    <h5 class="col-12 mt-3 pt-3">
                        ข้อมูลส่วนตัว
                    </h5>
                    <div class="col-5 mt-1 pt-1">
                        <label for="firstName">ชื่อ <span class="required">*</span></label>
                        <InputText class="w-100" v-model="firstName" toggleMask />
                    </div>
                    <div class="col-5 mt-1 pt-1">
                        <label for="lastName">นามสกุล</label>
                        <InputText v-model="lastName" id="lastName" class="w-100" toggleMask />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="email">Email</label>
                        <InputText id="email" class="w-100" v-model="email" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="idCardNo">หมายเลขบัตรประชาชน</label>
                        <InputText id="idCardNo" class="w-100" v-model="idCardNo" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="phoneNumber">หมายเลขโทรศัพท์</label>
                        <InputText id="phoneNumber" class="w-100" v-model="phoneNumber" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="role">เลือก role <span class="required">*</span></label>
                        <Dropdown v-model="role" :options="roles" optionLabel="name" class="w-100" />
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <Button type="submit" class="btn btn-success">บันทึก</Button>
            </div>

        </div>
    </form>
</template>
<script setup>
import { ref } from "vue";
import { instance } from '@/App.vue';
import router from '@/router';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';

import Message from 'primevue/message';
import { useToast } from 'primevue/usetoast';

const toast = useToast();

// top message 
const messages = ref([]);
var messagesId = 0;

var username = ref("");
var password = ref("");
var email = ref("");
var firstName = ref("");
var lastName = ref("");
var idCardNo = ref("");
var phoneNumber = ref("");
var role = ref({ name: "user" });
var roles = ref([{ name: "admin" }, { name: "user" }]);

function saveNewUser() {
    if (username.value == null) {
        // Toast Run
        toast.add({ severity: 'error', summary: 'error', detail: 'กรุณากรอกข้อมูลจำเป็นให้ครบ' });
    } else {
        var data = {
            "username": username.value,
            "password": password.value,
            "email": email.value,
            "idCardNo": idCardNo.value,
            "firstName": firstName.value,
            "lastName": lastName.value,
            "phoneNumber": phoneNumber.value,
            "role": role.value.name,
            "isActive": 1,
        };
        let headerConfig = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("soc_token")}`
            }
        };
        instance.post("/user", data, headerConfig)
            // eslint-disable-next-line
            .then(new_user_response => {
                console.log("[New User]: Success");
                router.replace("/user");
            }).catch(new_user_err => {
                console.log("[New User]: Fail");
                console.log("new_user_err", new_user_err);
                messages.value = [
                    { severity: 'error', content: 'เพิ่มผู้ใช้ไม่สำเร็จ', id: messagesId++ },
                ];
            });
    }
}
</script>
<style>
.required {
    color: red;
}
</style>