<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <div>
    <div class="flex flex-column topbar">
      <div class="row justify-content-end">
        <div class="col-auto pt-4">
          <button class="btn btn-primary" @click="newUser">
            เพิ่มผู้ใช้งาน
          </button>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="pt-3">
          <DataTable removableSort sortField="id" sortMode="multiple" :value="users" lazy
            :virtualScrollerOptions="{ itemSize: 30 }" tableStyle="min-width: 50rem;" dataKey="id"
            v-model:selection="selectedRow" selectionMode="single" @rowSelect="edit(selectedRow)">
            <Column field="id" header="ID" sortable headerClass="text-nowrap text-center"
              bodyClass="text-nowrap text-center" />
            <Column field="username" header="ชื่อผู้ใช้" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
            <Column field="email" header="Email" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
            <Column field="firstName" header="ชื่อ" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
            <Column field="lastName" header="นามสกุล" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
            <Column field="phoneNumber" header="หมายเลขโทรศัพท์" sortable headerClass="text-nowrap"
              bodyClass="text-nowrap" />
            <!-- <Column field="isActive" header="สถานะผู้ใช้" sortable headerClass="text-nowrap"
              bodyClass="text-nowrap text-center">
              <template #body="slotProps">
                <div v-if="slotProps.data.isActive == 1">เปิดใช้งาน</div>
                <div v-else>ปิดใช้งาน</div>
              </template>
            </Column> -->
            <!-- <Column field="status" header="Status" sortable headerClass="text-nowrap"
              bodyClass="text-nowrap text-center" /> -->
            <Column field="role" header="Role" sortable headerClass="text-nowrap" bodyClass="text-nowrap text-center" />
            <Column field="createdAt" header="วันที่สร้าง" sortable headerClass="text-nowrap" bodyClass="text-nowrap">
              <template #body="slotProps">
                <div>
                  {{ formatDate(slotProps.data.createdAt) }}
                </div>
              </template>
            </Column>
            <Column field="updatedAt" header="วันที่อัพเดท" sortable headerClass="text-nowrap" bodyClass="text-nowrap">
              <template #body="slotProps">
                <div>
                  {{ formatDate(slotProps.data.updatedAt) }}
                </div>
              </template>
            </Column>
            <Column :exportable="false" class="text-center">
              <template #body="slotProps">
                <Button class="data-cell-btn custom-data-cell-btn" icon="pi pi-pencil" severity="secondary"
                  @click="edit(slotProps.data)"></Button>
              </template>
            </Column>
            <Column :exportable="false" class="text-center" body-style="">
              <template #body="slotProps">
                <Button v-if="slotProps.data.role != 'admin'" class="data-cell-btn custom-data-cell-btn"
                  icon="pi pi-trash" severity="danger" @click="confirmDelete(slotProps.data)"
                  style="color: var(--status-closed-red)"></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js" setup>
import { ref, onMounted } from 'vue';
import { instance, logout, formatDate } from '@/App.vue';
import router from '@/router';

import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

// var filters = ref();
const confirm = useConfirm();
const toast = useToast();
const selectedRow = ref();

const confirmDelete = (data) => {
  confirm.require({
    message: 'Do you want to delete this record?',
    header: 'Danger Zone',
    icon: 'pi pi-info-circle',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    rejectClass: 'p-button-secondary p-button-outlined',
    acceptClass: 'p-button-danger',
    accept: () => {
      instance.delete(`/user/${data.id}`, {
        headers:
          { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
      })
        // eslint-disable-next-line
        .then(delete_user_response => {
          toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
          if (data.username == localStorage.getItem("soc_user")) {
            logout();
            router.replace("/");
          }
        }).catch(delete_user_err => {
          console.log("delete_user_err");
          console.log(delete_user_err);
          toast.add({ severity: 'error', summary: 'Failed', detail: 'Record is not deleted.', life: 3000 });
        });
    },
    reject: () => {
      toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
  });
};

var users = ref();

function fetchData() {
  instance.get('/user', {
    headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
  }).then(user_response => {
    users.value = user_response.data.data;
  });
}

onMounted(() => {
  // callUser({}, (user_res => {
  //   console.log(user_res);
  // }), (user_err => {
  //   console.log("[user err]");
  //   console.log(user_err);
  // }));
  fetchData();
});


/**
 * callCase
 *
 * data is Object
 *
 * data key: order, page, limit, search, sortField
 */
// eslint-disable-next-line no-unused-vars
function callUser(data, thenCallback, errCallBack) {
  var sort = "";
  var url = `/user?sort=${sort}`;
  if (url == "/user?search=") {
    url = "/user";
  }

  instance.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
  ).then(callCase_res => {
    thenCallback(callCase_res);
  }).catch(callCase_err => {
    errCallBack(callCase_err);
  });
}

function edit(data) {
  router.push({ path: `/user/edit/${data.id}` });
}

function newUser() {
  router.push("/user/new");
}
</script>

<style></style>
