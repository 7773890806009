import { createRouter, createWebHistory } from 'vue-router'

import LoginPage from '../views/LoginPage.vue';
import DashboardView from '../views/DashboardView.vue';
import UserPage from '../views/users/UserView.vue';
import NewUser from '../views/users/NewUser.vue';
import EditUser from '../views/users/EditUser.vue';
import CaseHome from '../views/case/CaseHome.vue';
import NewCasePage from '../views/case/NewCasePage.vue';
import EditCase from '../views/case/EditCase.vue';
import ConfigHome from '../views/config/ConfigHome.vue';
import TwoFAAuthPage from '../views/TwoFAAuthPage.vue';
import LineHomePage from '../views/line/lineHomePage.vue';
// import LineLogin from '../views/line/lineLogin.vue';
// import store from '@/store';


const routes = [
  {
    path: '/',
    name: '',
    // component: ()=> import('../views/index.vue')
    // eslint-disable-next-line no-unused-vars
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.

      if (localStorage.getItem('soc_token') != null) {
        return { path: '/dashboard' };
      } else {
        // return { path: '/search', query: { q: to.params.searchText } };
        return { path: '/login' };
        // return { path: '/line' };
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { title: 'ลงชื่อเข้าใช้ - VueSocApp' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { title: 'แดชบอร์ด - VueSocApp' },
  },
  {
    path: '/case',
    name: 'case',
    component: CaseHome,
    meta: { title: 'เคส - VueSocApp' },
  },
  {
    path: '/case/new',
    name: 'newCase',
    component: NewCasePage,
    meta: { title: 'เพิ่มเคสใหม่ - VueSocApp' },
  },
  {
    path: '/case/edit/:id',
    name: 'editCase',
    component: EditCase,
    meta: { title: 'แก้ไขเคส - VueSocApp' },
  },
  {
    path: '/user',
    name: 'user',
    component: UserPage,
    meta: { title: 'ผู้ใช้งาน - VueSocApp' },
  },
  {
    path: '/user/new',
    name: 'NewUserPage',
    component: NewUser,
    meta: { title: 'เพิ่มผู้ใช้งานใหม่ - VueSocApp' },
  },
  {
    path: '/user/edit/:id',
    name: 'EditUser',
    component: EditUser,
    meta: { title: 'แก้ไขผู้ใช้งาน - VueSocApp' },
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigHome,
    meta: { title: 'ตั้งค่า - VueSocApp' },
  },
  // {
  //   path: '/line',
  //   name: 'line',
  //   component: LineLogin,
  //   meta: { title: 'ลงชื่อเข้าใช้ - VueSocApp' },
  // },
  {
    path: '/two-authentication',
    name: 'two-authentication',
    component: TwoFAAuthPage,
    meta: { title: 'ยืนยันตัวตน 2 ขั้นตอน - VueSocApp' },
  },

  {
    path: '/line',
    name: 'line',
    component: LineHomePage,
    meta: { title: 'ไล์แจ้งเตือน - VueSocApp' },
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;