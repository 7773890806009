<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <!-- <div class="topbar" style="width: 83.33%">
        <button type="submit" class="btn btn-success">Update</button>
    </div> -->

    <transition-group name="p-message" tag="div">
        <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
    </transition-group>

    <form @submit.prevent="updateUser">

        <div class="flex flex-column">
            <div class="row">
                <h5 class="col-12 mt-2 pt-2">
                    ข้อมูลบัญชี
                </h5>
                <div class="col-5 mt-2 pt-2">
                    <label for="username">ชื่อผู้ใช้ <span class="required">*</span></label>
                    <InputText class="w-100" type="text" id="username" v-model="username" required />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="isActive" style="margin-right: 16px !important;">
                        สถานะผู้ใช้ <span class="required">*</span>
                    </label>
                    <Dropdown v-model="isActive" :options="isActives" optionLabel="name" optionValue="value"
                        class="w-100" />
                </div>
                <Divider class="mt-3 pt-3" />
                <h5 class="col-12">
                    ข้อมูลส่วนตัว
                </h5>
                <div class="col-5 mt-2 pt-2">
                    <label for="firstName">ชื่อ <span class="required">*</span></label>
                    <InputText class="w-100" type="text" id="firstName" v-model="firstName" required />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="lastName">นามสกุล</label>
                    <InputText class="w-100" type="text" id="lastName" v-model="lastName" />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="email">Email</label>
                    <InputText class="w-100" type="email" id="email" v-model="email" required />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="idCardNo">หมายเลขบัตรประชาชน</label>
                    <InputText class="w-100" type="text" id="idCardNo" v-model="idCardNo" required />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="phoneNumber">หมายเลขโทรศัพท์</label>
                    <InputText class="w-100" type="tel" id="phoneNumber" v-model="phoneNumber" />
                </div>
                <div class="col-5 mt-2 pt-2">
                    <label for="role" style="margin-right: 16px !important;">
                        Role <span class="required">*</span>
                    </label>
                    <Dropdown v-model="role" :options="roles" optionLabel="name" class="w-100" />
                </div>

            </div>
            <div class="row">
                <div class="col-1 ml-8">
                    <button type="submit" class="btn btn-success btn-block  mt-4">บันทึก</button>
                </div>
            </div>
        </div>
    </form>
    <Divider class=" mt-3 pt-3" />
    <form>
        <div class="flex flex-column">
            <div class="row">
                <h5 class="col-12">
                    เปลี่ยนรหัสผ่าน
                </h5>
                <div class="col-5 mt-2 pt-2">
                    <label for="newPassword">รหัสผ่านใหม่</label>
                    <Password class="w-100" id="newPassword" v-model="newPassword" toggle-mask :feedback="true"
                        required />
                </div>
            </div>
            <div class="mt-4">
                <Button type="button" @click="confirmChangePassword" class="btn btn-danger btn-block">
                    เปลี่ยนรหัสผ่าน
                </Button>
            </div>
        </div>


    </form>

</template>
<script setup>
import { ref, onMounted } from "vue";
import { instance } from '@/App.vue';
import router from '@/router';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import Password from 'primevue/password';
import Message from 'primevue/message';
import Toast from "primevue/toast";
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

const confirm = useConfirm();
const toast = useToast();


onMounted(() => {
    fetchData(router);
});

// top message 
const messages = ref([]);
var messagesId = 0;

// data
var id = ref();
var username = ref();
var password = ref();
var email = ref();
var firstName = ref();
var lastName = ref();
var phoneNumber = ref();
var role = ref();
var idCardNo = ref();
var isActive = ref(1);
// var oldPassword = ref();
var newPassword = ref();

// options
var roles = ref([{ name: "admin" }, { name: "user" }]);
var isActives = ref([{ name: "เปิดใช้งาน", value: 1 }, { name: "ปิดใช้งาน", value: 0 }]);

function fetchData(fetchDataRouter) {
    console.log(fetchDataRouter);

    var user_id = fetchDataRouter.currentRoute.value.params.id;
    id.value = user_id;

    instance.get(`/user/${user_id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    }).then(user_res => {
        console.log("user_res", user_res);
        username.value = user_res.data.username;
        email.value = user_res.data.email;
        firstName.value = user_res.data.firstName;
        lastName.value = user_res.data.lastName;
        idCardNo.value = user_res.data.idCardNo
        phoneNumber.value = user_res.data.phoneNumber;
        role.value = { name: user_res.data.role };
        password.value = user_res.data.password;
        isActive.value = user_res.data.isActive;
    }).catch(user_err => {
        console.log("user_err");
        console.log(user_err);
    });
}

function updateUser() {
    console.log("[New User]: start");
    var data = {
        "id": id.value,
        "username": username.value,
        "email": email.value,
        "firstName": firstName.value,
        "lastName": lastName.value,
        "idCardNo": idCardNo.value,
        "phoneNumber": phoneNumber.value,
        "role": role.value.name,
        "isActive": isActive.value,
    };

    console.log("data", data);

    instance.patch(`/user/${id.value}`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    })
        // eslint-disable-next-line
        .then(new_user_response => {
            console.log("[New User]: Success");
            router.replace("/user");
        }).catch(new_user_err => {
            console.log("[New User]: Fail");
            console.log("new_user_err", new_user_err);
            messages.value = [
                { severity: 'error', content: 'Add fail', id: messagesId++ },
            ];
        });
}


function confirmChangePassword() {
    confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'Cancel',
        acceptLabel: 'Save',
        accept: () => {
            changePassword();
        },
        reject: () => {
            toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

function changePassword() {
    var data = { password: newPassword.value };
    instance.patch(`/user/change_password/${id.value}`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    })
        // eslint-disable-next-line
        .then(changePass_res => {
            newPassword.value = "";
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Password is changed.', life: 3000 });
        }).catch(changePass_err => {
            console.log(changePass_err);
        });
}
</script>
<style></style>