<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div>
      <div class="flex flex-column topbar">
        <div class="row justify-content-end">
          <div class="col-auto pt-4">
            <!-- <button class="btn btn-primary" :href="urlLineAuth" @click="urlLineAuth" > -->
            <button class="btn btn-primary" :href="urlLineAuth" @click="urlLineAuth" >
              เพิ่มช่วงทางการแจ้งเตือน
            </button>
            <!-- <a target="_blank" :href="urlLineAuth" class="btn btn-primary">เชื่อมต่อ LINE</a> -->
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="pt-3">
            <DataTable removableSort sortField="id" sortMode="multiple" :value="lines" lazy
              :virtualScrollerOptions="{ itemSize: 30 }" tableStyle="min-width: 50rem;" dataKey="id"
              v-model:selection="selectedRow" selectionMode="single">
              <Column field="id" header="ID" sortable headerClass="text-nowrap text-center"
                bodyClass="text-nowrap text-center" />
              <!-- <Column field="userId.first_name" header="ชื่อผู้ใช้" sortable headerClass="text-nowrap" bodyClass="text-nowrap" /> -->
              <Column field="users.first_name" header="ชื่อผู้ใช้" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
              <Column field="lineType" header="ประเภทไลน์" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
              <Column field="lineName" header="ชื่อไลน์" sortable headerClass="text-nowrap" bodyClass="text-nowrap" />
              <Column field="createdAt" header="วันที่สร้าง" sortable headerClass="text-nowrap" bodyClass="text-nowrap">
                <template #body="slotProps">
                  <div>
                    {{ formatDate(slotProps.data.createdAt) }}
                  </div>
                </template>
              </Column>
              <Column field="updatedAt" header="วันที่อัพเดท" sortable headerClass="text-nowrap" bodyClass="text-nowrap">
                <template #body="slotProps">
                  <div>
                    {{ formatDate(slotProps.data.updatedAt) }}
                  </div>
                </template>
              </Column>
              <Column :exportable="false" class="text-center" body-style="">
                <template #body="slotProps">
                  <Button v-if="slotProps.data.role != 'admin'" class="data-cell-btn custom-data-cell-btn"
                    icon="pi pi-trash" severity="danger" @click="confirmDelete(slotProps.data)"
                    style="color: var(--status-closed-red)"></Button>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="js" setup>
   // eslint-disable-next-line no-unused-vars
  import { ref, onMounted, computed } from 'vue';
  import { instance, formatDate } from '@/App.vue';
  
  import ConfirmDialog from 'primevue/confirmdialog';
  import Toast from 'primevue/toast';
  import { useConfirm } from "primevue/useconfirm";
  import { useToast } from "primevue/usetoast";
  
  // var filters = ref();
  const confirm = useConfirm();
  const toast = useToast();
  const selectedRow = ref();
  
  const confirmDelete = (data) => {
    console.log('confirmDelete')
    console.log(data)
    confirm.require({
      message: 'Do you want to delete this record?',
      header: 'Danger Zone',
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      rejectClass: 'p-button-secondary p-button-outlined',
      acceptClass: 'p-button-danger',
      accept: () => {
        instance.delete(`line/revoke/${data.id}`, {
          headers:
            { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
        })
          // eslint-disable-next-line
          .then(delete_user_response => {
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
            // if (data.username == localStorage.getItem("soc_user")) {
            //   logout();
            //   router.replace("/");
            // }
            fetchData()
          }).catch(delete_user_err => {
            console.log("delete_user_err");
            console.log(delete_user_err);
            toast.add({ severity: 'error', summary: 'Failed', detail: 'Record is not deleted.', life: 3000 });
          });
      },
      reject: () => {
        toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
    });
  };
  
  var lines = ref();
  
  function fetchData() {
    instance.get('/line', {
      headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
    }).then(user_response => {
        console.log(user_response.data)
      lines.value = user_response.data;
    });
  }
  
  onMounted(() => {
    // callUser({}, (user_res => {
    //   console.log(user_res);
    // }), (user_err => {
    //   console.log("[user err]");
    //   console.log(user_err);
    // }));
    fetchData();
  });

  
  
  
  /**
   * callCase
   *
   * data is Object
   *
   * data key: order, page, limit, search, sortField
   */
  // eslint-disable-next-line no-unused-vars
  function callUser(data, thenCallback, errCallBack) {
    var sort = "";
    var url = `/user?sort=${sort}`;
    if (url == "/user?search=") {
      url = "/user";
    }
  
    instance.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
    ).then(callCase_res => {
      thenCallback(callCase_res);
    }).catch(callCase_err => {
      errCallBack(callCase_err);
    });
  }

//   const urlLineAuth = computed(() => {
//         console.log('urlLineAuth')
//         const clientId = "lVbOnYetkPwPtmpsyabPa4"//client id ใน line notify service
//         const engine = 'http://localhost:3000'// url backend สำหรับ redirect
//         const username = localStorage.getItem("soc_user")// ผมใช้เป็น username เพื่อในไปใช้หา user ต่อที่ backend
//         return `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${engine}/line/redirect&scope=notify&state=${username}`
//         // window.location.href( `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${engine}/line/redirect&scope=notify&state=${username}` )
//     })

    function urlLineAuth() {
        console.log('urlLineAuth')
        const clientId = "lVbOnYetkPwPtmpsyabPa4"//client id ใน line notify service
        // const engine = 'http://localhost:3000'// url backend สำหรับ redirect
        const engine = 'http://146.190.95.17:3002'// url backend สำหรับ redirect
        const username = localStorage.getItem("soc_user_id")// ผมใช้เป็น username เพื่อในไปใช้หา user ต่อที่ backend
        // return `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${engine}/line/redirect&scope=notify&state=${username}`
        window.location.replace(`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${engine}/line/redirect&scope=notify&state=${username}`)
    }
  

  
  
  </script>
  
  <style></style>
  