<!-- eslint-disable vue/no-unused-vars -->
<template>
    <Toast header-class="text-nowrap" />
    <ConfirmDialog></ConfirmDialog>
    <div>
        <div class="flex flex-column pt-1 pb-1">
            <div class="row justify-content-end px-0">
                <!-- <div class="col"></div> -->
                <div class="col-3 px-1 mt-3">
                    <FloatLabel>
                        <Dropdown v-model="selectFilterOption" id="selectFilterOption" :options="selectFilterOptions"
                            option-label="name" class="w-100" v-on:change="onFilterDropdownChange"
                            header-class="text-nowrap" />
                        <label for="selectFilterOption">ตัวเลือกการกรอง</label>
                    </FloatLabel>
                </div>
                <div class="col-auto  px-1 mt-3" v-if="isDatePickerShow">
                    <FloatLabel>
                        <Calendar v-model="filterStartDate" id="filterStartDate" panelStyle="width: 50%"
                            date-format="dd/mm/yy" :show-time="false" hour-format="" header-class="text-nowrap" />
                        <label for="filterStartDate">วันที่เริ่มต้น</label>
                    </FloatLabel>
                </div>
                <div class="col-auto  px-1 mt-3" v-if="isDatePickerShow">
                    <FloatLabel>
                        <Calendar v-model="filterEndDate" id="filterEndDate" panelStyle="width: 50%"
                            date-format="dd/mm/yy" :show-time="false" hour-format="" :hide-on-range-selection="true"
                            header-class="text-nowrap" />
                        <label for="filterEndDate">วันที่สิ้นสุด</label>
                    </FloatLabel>
                </div>
                <div class="col-auto px-1 mt-3" v-if="isDatePickerShow">
                    <Button class="btn btn-outline-dark" label="Filter" @click="filter" header-class="text-nowrap" />
                </div>
                <div class="col-auto px-1 mt-3">
                    <Button class="btn btn-outline-dark" @click="updateWhoIs" label="อัพเดท WhoIs ทั้งหมด"
                        header-class="text-nowrap" />
                </div>
                <div class="col-auto px-1 mt-3">
                    <Button class="btn btn-outline-dark" @click="checkWebsite" label="ตรวจสอบเว็บไซต์ทั้งหมด"
                        header-class="text-nowrap" />
                </div>
                <div class="col-auto px-1 mt-3">
                    <FileUpload class="btn btn-outline-dark" mode="basic" id="uploadFile" name="file" :url="uploadApi"
                        accept="text/csv" :maxFileSize="1000000" @before-send="beforeUpload" @error="uploadError"
                        chooseLabel="นำเข้าข้อมูล" header-class="text-nowrap" />
                </div>
                <div class="col-auto px-1 mt-3">
                    <Button class="btn btn-outline-dark" @click="exportExcel">นำออกข้อมูล</Button>
                </div>
                <div class="col-auto pl-1 mt-3">
                    <Button class="btn btn-primary" @click="newCase">เพิ่มเคสใหม่</Button>
                </div>
            </div>
        </div>
        <div class="flex flex-column justify-content-end pt-3">
            <div class="row">
                <div class="col"></div>
                <div class="col-5 pr-0">
                    <FloatLabel>
                        <InputText v-model="filterSearch" id="filterSearch" class=" w-100" v-on:change="onSearchChange"
                            header-class="text-nowrap" />
                        <label for="filterSearch">ค้นหา</label>
                    </FloatLabel>
                </div>
            </div>
        </div>
        <div class="pt-3">
            <DataTable :value="cases" ref="case_dt" :showGridlines="true" lazy sortMode="multiple" :paginator="true"
                :total-records="totalRecords" :rowsPerPageOptions="rowsPerPageOptions" :page="page" :first="first"
                v-on:page="onPage" :rows="limitRowNumber" :size="size" v-on:sort="onSort"
                v-model:selection="selectedRow" selectionMode="single" @rowSelect="edit(selectedRow)">
                <Column field="id" header="ลำดับที่" dataType="number" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap text-center" />
                <Column field="status" header="Status" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap text-center">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.status == 1"
                            style="padding: 0rem !important; color: white; font-weight: 600; background-color: green;">
                            เปิด
                        </div>
                        <div v-else
                            style="padding: 0rem !important; color: white; font-weight: 600; background-color: var(--status-closed-red);">
                            ปิดแล้ว
                        </div>
                    </template>
                </Column>
                <!-- <Column field="createdAt" header="วัน/ เวลา สถานที่เกิดเหตุ" sortable
                    body-class=" text-nowrap text-center " header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.createdAt) }}
                        </div>
                    </template>
                </Column> -->
                <Column field="threatsCategory" header="รูปแบบภัยคุกคาม" sortable body-class=" text-nowrap  "
                    header-class="text-nowrap" />
                <Column field="incidentClass" header="Incident Class" style="width: 200px" sortable
                    body-class=" text-nowrap text-center" header-class="text-nowrap" />
                <!-- <Column field="countermeasure" header="การปฏิบัติในการรับมือ" sortable
                    body-class=" text-nowrap text-center" header-class="text-nowrap" />
                <Column field="department" header="ประเภทหน่วยงาน" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="departmentNumber" header="เลขกำกับหน่วยงาน" sortable
                    body-class=" text-nowrap text-center" header-class="text-nowrap" />
                <Column field="issuedBy" header="ฝ่ายที่ออกเคส" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="issuedYear" header="ปีที่ออกเคส" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="scamCaseNo" header="ลำดับที่เคส" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="investigateSolution" header="การปฏิบัติการในการค้นหาเคส" sortable
                    body-class=" text-nowrap text-center" header-class="text-nowrap" />
                <Column field="scamType" header="ประเภทเคส" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="scamSystemName" header="ชื่อระบบ/เว็บไซต์" style="width: 300px" sortable
                    body-class=" text-nowrap" header-class="text-nowrap" />
                <Column field="scamDepartmentName" header="ชื่อหน่วยงาน" style="width: 500px !important" sortable
                    body-class=" text-nowrap" header-class="text-nowrap" />
                <Column field="region" header="ภูมิภาค" sortable body-class=" text-nowrap" header-class="text-nowrap" />
                <Column field="province" header="จังหวัด" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="domain" header="ชื่อ Domain" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="majorAffiliation" header="สังกัดใหญ่" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="subAffiliation" header="สังกัดย่อย" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="scamTypeNumber" header="รหัสประเภท" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="informedChanel" header="รับแจ้ง" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="informerName" header="ชื่อผู้แจ้ง" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" /> -->
                <Column field="phishingUrl" header="Phishing URL" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <!-- <Column field="phishingUrlEncode" header="Phishing URL Encode" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />

                <Column field="lineUrl" header="Line URL" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="userId" header="User Id" sortable body-class=" text-nowrap text-center"
                    header-class="text-nowrap" />
                <Column field="lastCheckTime" header="Last Check Time" sortable body-class="text-center text-nowrap"
                    header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.lastCheckTime) }}
                        </div>
                    </template>
                </Column>
                <Column field="whoisCreatedDate" header="Whois Created Date" sortable
                    body-class="text-center text-nowrap" header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.whoisCreatedDate) }}
                        </div>
                    </template>
                </Column>
                <Column field="whoisExpireDate" header="Whois Expire Date" sortable body-class="text-center text-nowrap"
                    header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.whoisExpireDate) }}
                        </div>
                    </template>
                </Column>
                <Column field="whoisUpdatedDate" header="Whois Updated Date" sortable
                    body-class="text-center text-nowrap" header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.whoisUpdatedDate) }}
                        </div>
                    </template>
                </Column>
                <Column field="whoisNameServer" header="Whois Name Server" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="whoisIpAddress" header="Whois IP Address" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" />
                <Column field="whoisAsn" header="Whois ASN" sortable body-class=" text-nowrap"
                    header-class="text-nowrap" /> -->
                <Column field="incident_time" header="วันที่ได้รับแจ้งเหตุ" sortable
                    body-class="text-center text-nowrap" header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.closedAt) }}
                        </div>
                    </template>
                </Column>
                <Column field="closedAt" header="วันที่ปิดเคส" sortable
                    body-class="text-center text-nowrap" header-class="text-nowrap">
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.closedAt) }}
                        </div>
                    </template>
                </Column>
                <Column :exportable="false" class="text-center">
                    <template #body="slotProps">
                        <Button class="data-cell-btn custom-data-cell-btn" icon="pi pi-pencil" severity="secondary"
                            @click="edit(slotProps.data)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { addNumberToNumberArray, getTimeFilter, instance, formatDate } from '@/App.vue';
// import { addNumberToNumberArray, getTimeFilter, instance } from '@/App.vue';
import router from '@/router';

import FileUpload from 'primevue/fileupload';
import ConfirmDialog from 'primevue/confirmdialog';
// import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { exportBlob } from '@/utils/utils';


// const confirm = useConfirm();
const toast = useToast();
var size = ref({ value: 'small' });
var filterSearch = ref("");
var selectFilterOption = ref({ name: "ทั้งหมด" });
var filterStartDate = ref(null);
var filterEndDate = ref(null);
var currentSortField = "";
var currentSortFieldOrder = "";
const selectedRow = ref();

onMounted(() => {
    fetchData();
    userId.value = localStorage.getItem("soc_user_id");
    uploadUrl.value = `/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`;
});

var page = ref(1);
var first = ref(1);
var totalRecords = ref(0);
var limitRowNumber = ref(0);
var userId = ref("");
var case_dt = ref("");
var cases = ref();
var uploadUrl = ref("");
const defaultRowsPerPageOptions = [5, 10, 20, 50];
var rowsPerPageOptions = ref(defaultRowsPerPageOptions);
// var uploadApi = `http://146.190.95.17:3001/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`;
var uploadApi = `${process.env.BACKEND_API || 'http://146.190.95.17:3002'}/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`
// var uploadApi = `http://ncsa-nestjs-backend-service:3000/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`
// var uploadApi = `${process.env.BACKEND_API || 'http://localhost:3000'}/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`


var isDatePickerShow = ref(false);
const selectFilterOptions = ref([{ name: "ทั้งหมด" }, { name: "วัน" }, { name: "สัปดาห์" }, { name: "เดือน" }, { name: "ปี" }, { name: "เลือกเอง" }])

function onFilterDropdownChange(event) {
    isDatePickerShow.value = (event.value.name == "เลือกเอง");
    if (event.value.name != "เลือกเอง") {
        filter();
    }
}

/**
 * return dashboard filter URL query time string
 */
function casesFilterQueryTimeString() {
    const currentDate = new Date();
    var startDate = new Date(currentDate);

    var timeFilter = "";
    if (selectFilterOption.value.name == "วัน") {
        timeFilter = getTimeFilter(currentDate);
    } else if (selectFilterOption.value.name == "สัปดาห์") {
        startDate.setDate(currentDate.getDate() - 7);
        timeFilter = getTimeFilter(startDate, currentDate);
    } else if (selectFilterOption.value.name == "เดือน") {
        startDate.setDate(currentDate.getDate() - 30);
        timeFilter = getTimeFilter(startDate, currentDate);
    } else if (selectFilterOption.value.name == "ปี") {
        timeFilter = getTimeFilter(new Date(currentDate.getFullYear(), 0, 1), currentDate);
    }
    else {
        timeFilter = getTimeFilter(filterStartDate.value, filterEndDate.value);
    }

    return timeFilter;
}


/**
 * callCase
 * 
 * data is Object
 * 
 * data key: order, page, limit, search, sortField
 */
function callCase(data, thenCallback, errCallBack) {
    // var timeFilter = getTimeFilter(filterStartDate.value, filterEndDate.value);
    var timeFilter = casesFilterQueryTimeString();
    var search = filterSearch.value;
    var time = "";
    var sort = "";
    var order = "";
    var page = "";
    var limit = "";
    if (data != null && data != {}) {
        if (data.search != null) {
            search = data.search;
        }
        if (timeFilter != null && timeFilter != "&start=") {
            time = timeFilter;
        }
        if (data.sortField != null && data.sortField != "") {
            sort = `&sort=${data.sortField}`;
        }
        if (data.order != null && data.order != "") {
            order = `&order=${data.order}`;
        }
        if (data.page != null && data.page != "") {
            page = `&page=${data.page}`;
        }
        if (data.limit != null && data.limit != "") {
            if (limit > totalRecords.value) {
                limit = `&limit=${totalRecords.value}`;
            } else {
                limit = `&limit=${data.limit}`;
            }
        }
    }
    var url = `/scam?search=${search}${time}${sort}${order}${page}${limit}`;
    if (url == "/scam?search=") {
        url = "/scam";
    }

    instance.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` } }
    ).then(callCase_res => {
        thenCallback(callCase_res);
    }).catch(callCase_err => {
        errCallBack(callCase_err);
    });
}

function filter() {
    callCase({ page: page.value, limit: limitRowNumber.value },
        (filter_res => {
            cases.value = filter_res.data.data;
            totalRecords.value = filter_res.data.meta.itemCount;
            rowsPerPageOptions.value = defaultRowsPerPageOptions;
            rowsPerPageOptions.value = addNumberToNumberArray(rowsPerPageOptions.value, filter_res.data.meta.itemCount);
        }), (filter_err => {
            console.log("[filter err]");
            console.log(filter_err);
        }));
}

function fetchData() {
    callCase({ page: page.value, limit: 10 },
        (fetchData_res => {
            console.log(fetchData_res.data.data);
            cases.value = fetchData_res.data.data;
            totalRecords.value = fetchData_res.data.meta.itemCount;
            limitRowNumber.value = 10;
            rowsPerPageOptions.value = addNumberToNumberArray(rowsPerPageOptions.value, fetchData_res.data.meta.itemCount);
        }),
        (fetchData_err => {
            console.log("[fetchData error]");
            console.log(fetchData_err);
        })
    );
}

function onSearchChange(event) {

    var changeValue = event.srcElement.value;

    callCase({ search: changeValue, page: page.value, limit: limitRowNumber.value, sortField: currentSortField },
        (search_res => {
            cases.value = search_res.data.data;
            totalRecords.value = search_res.data.meta.itemCount;
            rowsPerPageOptions.value = defaultRowsPerPageOptions;
            rowsPerPageOptions.value = addNumberToNumberArray(rowsPerPageOptions.value, search_res.data.meta.itemCount);
        }), (search_err => {
            console.log("[search err]");
            console.log(search_err);
        }));
}

function onSort(event) {
    currentSortField = event.multiSortMeta[0].field;
    var sortFieldOrder = event.multiSortMeta[0].order;
    currentSortFieldOrder = (sortFieldOrder > 0) ? "ASC" : "DESC";
    first.value = 0;
    page.value = 0;

    callCase({ sortField: currentSortField, order: currentSortFieldOrder, page: page.value, limit: limitRowNumber.value },
        (sort_res => {
            cases.value = sort_res.data.data;
            totalRecords.value = sort_res.data.meta.itemCount;
            rowsPerPageOptions.value = defaultRowsPerPageOptions;
            rowsPerPageOptions.value = addNumberToNumberArray(rowsPerPageOptions.value, sort_res.data.meta.itemCount);
        }),
        (sort_err => {
            console.log('[sort err]');
            console.log(sort_err);
        })
    );
}

function onPage(event) {
    limitRowNumber.value = event.rows;
    first.value = event.first + 1;
    page.value = event.page + 1;

    callCase({ page: page.value, limit: event.rows, sortField: currentSortField, order: currentSortFieldOrder },
        (page_res => {
            cases.value = page_res.data.data;
            totalRecords.value = page_res.data.meta.itemCount;
            rowsPerPageOptions.value = defaultRowsPerPageOptions;
            rowsPerPageOptions.value = addNumberToNumberArray(rowsPerPageOptions.value, page_res.data.meta.itemCount);
        }),
        (page_err => {
            console.log("[page err]");
            console.log(page_err);
        })
    );
}

function exportExcel(event) {
    console.log(event);

    const fileName1 = `cases_excel.xlsx`;
    var url = `/scam/download_excel?${casesFilterQueryTimeString()}`;

    instance.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` },
        responseType: 'blob',
    })
        .then(excel_res => {
            const data = excel_res.data;
            exportBlob(data, fileName1);

        }).catch(excel_err => {
            console.log("excel_err", excel_err);
        });
}

function edit(data) {
    router.push({ path: `/case/edit/${data.id}` });
}

function newCase() {
    router.push("/case/new");
}

function uploadError(FileUploadErrorEvent) {
    console.log("[File Upload ErrorEvent]");
    console.log(FileUploadErrorEvent);
}

function beforeUpload(request) {
    // request.xhr.open('POST', `http://146.190.95.17:3001/scam/upload_file_csv/${localStorage.getItem("soc_user_id")}`, true);
    request.xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem("soc_token")}`);
    return request;
}

// const confirmDelete = (data) => {
//     confirm.require({
//         message: 'Do you want to delete this record?',
//         header: 'Danger Zone',
//         icon: 'pi pi-info-circle',
//         rejectLabel: 'Cancel',
//         acceptLabel: 'Delete',
//         rejectClass: 'p-Button-secondary p-Button-outlined',
//         acceptClass: 'p-Button-danger',
//         accept: () => {
//             instance.delete(`/scam/${data.id}`, {
//                 headers:
//                     { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
//             }).then(delete_user_response => {
//                 delete_user_response;
//                 toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
//             }).catch(delete_user_err => {
//                 console.log("[delete user err]");
//                 console.log(delete_user_err);
//                 toast.add({ severity: 'error', summary: 'Failed', detail: 'Record is not deleted.', life: 3000 });
//             });
//         },
//         reject: () => {
//             toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
//         }
//     });
// };

function updateWhoIs() {
    var header = {
        headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
    };

    instance.get("/scam/update_whois", header
    )
        // eslint-disable-next-line
        .then(update_res => {
            toast.add({ severity: 'info', summary: 'Success', detail: 'Data is updated.', life: 3000 });
        }).catch(update_error => {
            console.log("[syncData][update error]");
            console.log(update_error);
            toast.add({ severity: 'info', summary: 'Fail', detail: 'Data is failed to update.', life: 3000 });
        });
}

function checkWebsite() {
    var header = {
        headers: { Authorization: `Bearer ${localStorage.getItem("soc_token")}` }
    };
    instance.get("/scam/check_all_url", header
    )
        // eslint-disable-next-line
        .then(check_res => {
            toast.add({ severity: 'info', summary: 'Success', detail: 'Data is updated.', life: 3000 });
        }).catch(check_error => {
            console.log("[syncData][check error]");
            console.log(check_error);
            toast.add({ severity: 'info', summary: 'Fail', detail: 'Data is failed to update.', life: 3000 });
        });
}

</script>
<style>
.p-fileupload.p-fileupload-basic.p-component {
    margin-left: 0.5rem;
}

.p-float-label {
    margin-left: 0.5rem;
}

th {
    border-color: #e2e8f0 !important;
    border-bottom-color: gray !important;
    width: 300px !important;
    font-size: var(--table-font-size);
    color: white;
    background-color: #222251;
}

td {
    border-color: #e2e8f0 !important;
    font-size: var(--table-font-size);
    height: 1;
    padding: 0rem 0.5em 0rem 0.5rem;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    font-size: var(--table-font-size);
    border-width: 1px !important;
}
</style>