<template>
  <div class="flex flex-column" style="height: 100vh; width: 100vw;">
    <div class="row" style="height: 100%">
      <div class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 bg-dark p-0">
        <div class="nav px-0" style="height: 100%;  padding-top: 0px !important;">
          <ul class="nav-menu" style="height: 90%;">
            <li class="nav-item bg-light text-center">
              <router-link to="/dashboard" class="nav-link">
                <img src="../assets/images/2022-10-cropped-ncsa-logo.png" style="width: 100%; height: auto;" />
              </router-link>
            </li>
            <li class="nav-item mt-3 ml-2">
              <router-link to="/dashboard" class="nav-link">แดชบอร์ด</router-link>
            </li>
            <li class="nav-item ml-2">
              <router-link to="/case" class="nav-link">เคส</router-link>
            </li>
            <li class="nav-item ml-2" v-if="isAdmin">
              <router-link to="/user" class="nav-link">ผู้ใช้งาน</router-link>
            </li>
            <li class="nav-item ml-2" v-if="isAdmin">
              <router-link to="/line" class="nav-link">Line Notify</router-link>
            </li>

          </ul>
          <div class="text-center ml-3 p-3" style="width: 100%; color: white; vertical-align: bottom;">
            <router-link to="/login" class="btn btn-danger" style="width: 100%;">ออกจากระบบ</router-link>
          </div>
        </div>
      </div>

      <div class="col-8 col-sm-8 col-md-10 col-lg-10 col-xl-10" style="height: 100%">
        <header class="d-flex flex-wrap justify-content-between align-items-center px-3 py-3 mb-1 border-bottom">
          <div v-if="$router.currentRoute.value.fullPath == '/dashboard'">
            แดชบอร์ด
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/case'">
            เคส
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath.includes('/case/new')">
            <router-link to="/case" class="link">เคส</router-link><span class="link-separate "> / </span>เพิ่มเคสใหม่
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath.includes('/case/edit/')">
            <router-link to="/case" class="link">เคส</router-link><span class="link-separate "> / </span>แก้ไขเคส
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/user'">
            ผู้ใช้งาน
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/user/new'">
            <router-link to="/user" class="link">ผู้ใช้งาน</router-link><span class="link-separate "> /
            </span>เพิ่มผู้ใช้งานใหม่
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath.includes('/user/edit/')">
            <router-link to="/user" class="link">ผู้ใช้งาน</router-link><span class="link-separate "> /
            </span>แก้ไขข้อมูลผู้ใช้งาน
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config'">
            ตั้งค่า
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config/roles'">
            <router-link to="/config" class="link">ตั้งค่า</router-link><span class="link-separate "> / </span>User
            Roles
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config/channels'">
            <router-link to="/config" class="link">ตั้งค่า</router-link><span class="link-separate "> / </span>ช่องทาง
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config/threats'">
            <router-link to="/config" class="link">ตั้งค่า</router-link><span class="link-separate "> / </span>ภัยคุกคาม
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config/org'">
            <router-link to="/config" class="link">ตั้งค่า</router-link><span class="link-separate "> / </span>หน่วยงาน
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/config/provider'">
            <router-link to="/config" class="link">ตั้งค่า</router-link><span class="link-separate "> /
            </span>ผู้ให้บริการ
          </div>
          <div v-else-if="$router.currentRoute.value.fullPath == '/profile'">
            โปรไฟล์ของฉัน
          </div>
        </header>
        <div class="pt-1 pb-3 mx-2 container">
          <ScrollPanel style="width: 100%; height: calc(90vh); padding-right: 2rem; padding-bottom: 2rem;">
            <RouterView />
          </ScrollPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
// import Avatar from 'primevue/avatar';
// import TieredMenu from 'primevue/tieredmenu';
import { upperCase } from '@/App.vue';

var soc_user = ref();
var soc_user_firstLetter = ref();
var soc_role = ref();
var isAdmin = (localStorage.getItem("soc_role") == "admin");
// const menu = ref();
// const menuItems = ref([
//   {
//     separator: true
//   },
//   {
//     label: 'Logout',
//     icon: 'pi pi-sign-out',
//     command: logoutFn
//   }
// ]);

onMounted(() => {
  soc_user.value = localStorage.getItem("soc_user");
  soc_role.value = localStorage.getItem('soc_role');
  if (soc_user.value != null) {
    soc_user_firstLetter.value = upperCase(soc_user.value.charAt(0));
  }
});

// function logoutFn() {
//   console.log('localStorage', localStorage);
//   localStorage.clear();
//   console.log('localStorage', localStorage);
  
//   router.removeRoute();
//   router.go('/login');
// }


// function toggleLayoutMenu(event) {
//   menu.value.toggle(event);
// }



</script>

<style>
.p-menuitem-link span {
  color: black !important;
}
</style>
