<template>
    <form @submit.prevent="saveNewCase">
        <!-- <div class="topbar" style="width: 83.33%;">
            <button type="submit" class="btn btn-success">Save</button>
        </div> -->

        <transition-group name="p-message" tag="div">
            <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
        </transition-group>

        <div>
            <div class="flex flex-column">
                <div class="row">
                    <div class="col-5 mt-2 pt-2">
                        <label for="phishingUrl">Phishing URL</label>
                        <InputText class="w-100" id="phishingUrl" v-model="phishingUrl" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="phishingUrlEncode">Phishing URL Encode</label>
                        <InputText class="w-100" id="phishingUrlEncode" v-model="phishingUrlEncode" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="lineUrl">Link Line</label>
                        <InputText class="w-100" id="lineUrl" v-model="lineUrl" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="domain">ชื่อ Domain</label>
                        <InputText class="w-100" id="domain" v-model="domain" />
                    </div>
                    <div class="col-5 text-start mt-3 pt-3">
                        <label for="threatsCategory">รูปแบบภัยคุกคาม</label>
                        <Dropdown v-model="threatsCategory" id="threatsCategory" :options="threats" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-3 pt-3">
                        <label for="incidentClass">Incident Class</label>
                        <Dropdown v-model="incidentClass" id="incidentClass" :options="incidentClasses"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-3 pt-3">
                        <label for="counterMeasure">การปฏิบัติในการรับมือ</label>
                        <Dropdown v-model="counterMeasure" id="counterMeasure" :options="counterMeasures"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 text-start mt-3 pt-3">
                        <label for="filterStartDate">วัน/ เวลา สถานที่เกิดเหตุ</label>
                        <Calendar v-model="incidentTime" id="incidentTime" date-format="dd/mm/yy" :show-time="false"
                            style="width: 100%;" hour-format="" panelStyle="width: 50%" />
                    </div>

                    <Divider class="mt-3 pt-3" />
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamSystemName">ชื่อระบบ/เว็บไซต์</label>
                        <InputText class="w-100" id="scamSystemName" v-model="scamSystemName" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamDepartmentName">ขื่อหน่วยงาน</label>
                        <InputText class="w-100" id="scamDepartmentName" v-model="scamDepartmentName" />
                    </div>

                    <Divider class="mt-3 pt-3" />
                    <div class="col-5 mt-2 pt-2">
                        <label for="department">ประเภทหน่วยงาน</label>
                        <Dropdown v-model="department" id="department" :options="departments" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="departmentNumber">เลขกำกับหน่วยงาน</label>
                        <Dropdown v-model="departmentNumber" id="departmentNumber" :options="departmentNumbers"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="issuedBy">ฝ่ายที่ออกเคส</label>
                        <Dropdown v-model="issuedBy" id="issuedBy" :options="issuedBies" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="issuedYear">ปีที่ออกเคส</label>
                        <InputText v-model="issuedYear" id="issuedYear" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamCaseNo">Case Ref No</label>
                        <InputText v-model="scamCaseNo" id="scamCaseNo" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamType">ประเภทเคส</label>
                        <Dropdown v-model="scamType" id="scamType" :options="scamTypes" optionLabel="name"
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="scamTypeNumber">รหัสประเภท</label>
                        <Dropdown v-model="scamTypeNumber" id="scamTypeNumber" :options="scamTypeNumbers"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="investigateSolution">การปฏิบัติการในการค้นหาเคส</label>
                        <Dropdown v-model="investigateSolution" id="investigateSolution" :options="investigateSolutions"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="region">ภูมิภาค</label>
                        <Dropdown v-model="region" id="region" :options="regions" optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="province">จังหวัด</label>
                        <Dropdown v-model="province" id="province" :options="provinces" optionLabel="name" filter
                            class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="majorAffiliation">สังกัดใหญ่</label>
                        <InputText class="w-100" id="majorAffiliation" v-model="majorAffiliation" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="subAffiliation">สังกัดย่อย</label>
                        <InputText class="w-100" id="subAffiliation" v-model="subAffiliation" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="informedChannel">รับแจ้ง</label>
                        <Dropdown v-model="informedChannel" id="informedChannel" :options="informedChannels"
                            optionLabel="name" class="w-100" />
                    </div>
                    <div class="col-5 mt-2 pt-2">
                        <label for="informerName">ชื่อผู้แจ้ง</label>
                        <InputText class="w-100" id="informerName" v-model="informerName" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1 ml-8">
                <button type="submit" class="btn btn-success btn-block flex-end  mt-4">Save</button>
            </div>
        </div>
    </form>
</template>
<script setup>
import threatsData from '../../assets/json/threats.json';
import incidentClassesData from '../../assets/json/incidentClasses.json';
import counterMeasuresData from '../../assets/json/counterMeasures.json';
import departmentsData from '../../assets/json/departments.json';
import departmentNumbersData from '../../assets/json/departmentNumbers.json';
import issuedBiesData from '../../assets/json/issuedBies.json';
import investigateSolutionsData from '../../assets/json/investigateSolutions.json';
import scamTypesData from '../../assets/json/scamTypes.json';
import regionsData from '../../assets/json/regions.json';
import informedChannelsData from '../../assets/json/informedChannels.json';
import scamTypeNumbersData from '../../assets/json/scamTypeNumbers.json';
import provincesData from '../../assets/json/provinces.json';

import { ref, onMounted } from "vue";
import { instance } from '@/App.vue';
import router from '@/router';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import Divider from 'primevue/divider';
import Message from 'primevue/message';

// top message 
const messages = ref([]);
var messagesId = 0;

onMounted(() => {
    fromCaseId.value = router.currentRoute.value.query.from;

    if (fromCaseId.value != null && fromCaseId.value != "" && fromCaseId.value != undefined) {
        console.log("It's duplicated");
        setDataFromCase(fromCaseId.value);
    }
    userId.value = localStorage.getItem("soc_user_id");
});


// Data
var userId = ref("");
var phishingUrl = ref("");
var phishingUrlEncode = ref("");
var threatsCategory = ref({});
var incidentClass = ref({});
var counterMeasure = ref({});
var department = ref({});
var departmentNumber = ref({});
var issuedBy = ref({});
var issuedYear = ref("");
var scamCaseNo = ref();
var investigateSolution = ref({});
var scamType = ref({});
var scamSystemName = ref("");
var scamDepartmentName = ref("");
var region = ref({});
var province = ref({});
var domain = ref("");
var majorAffiliation = ref("");
var subAffiliation = ref("");
var scamTypeNumber = ref({});
var informedChannel = ref({});
var informerName = ref("");
var lineUrl = ref("");
var incidentTime = ref(null);

var fromCaseId = ref();

// Option List
var threats = ref(threatsData);
var incidentClasses = ref(incidentClassesData);
var counterMeasures = ref(counterMeasuresData);
var departments = ref(departmentsData);
var departmentNumbers = ref(departmentNumbersData);
var issuedBies = ref(issuedBiesData);
var investigateSolutions = ref(investigateSolutionsData);
var scamTypes = ref(scamTypesData);
var regions = ref(regionsData);
var provinces = ref(provincesData);
var scamTypeNumbers = ref(scamTypeNumbersData);
var informedChannels = ref(informedChannelsData);


function saveNewCase() {
    console.log("[New Case]: start");

    var data = {
        "threatsCategory": threatsCategory.value.name,
        "incidentClass": incidentClass.value.name,
        "countermeasure": counterMeasure.value.name,
        "incidentTime": incidentTime.value,
        "department": department.value.name,
        "departmentNumber": departmentNumber.value.name,
        "issuedBy": issuedBy.value.name,
        "issuedYear": issuedYear.value,
        "investigateSolution": investigateSolution.value.name,
        "scamType": scamType.value.name,
        "scamSystemName": scamSystemName.value,
        "scamDepartmentName": scamDepartmentName.value,
        "region": region.value.name,
        "province": province.value.name,
        "domain": domain.value,
        "majorAffiliation": majorAffiliation.value,
        "subAffiliation": subAffiliation.value,
        "scamCaseNo": scamCaseNo.value,
        "scamTypeNumber": scamTypeNumber.value.name,
        "informedChanel": informedChannel.value.name,
        "informerName": informerName.value,
        "phishingUrl": phishingUrl.value,
        "phishingUrlEncode": phishingUrlEncode.value,
        "lineUrl": lineUrl.value,
        "userId": parseInt(userId.value),
    };
    let headerConfig = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    };
    // eslint-disable-next-line
    instance.post("/scam", data, headerConfig).then(new_case_response => {
        console.log("[New Case]: Success");
        router.replace("/case");
        router.back();
    }).catch(new_case_err => {
        console.log("[New Case]: Fail");
        console.log("new_case_err", new_case_err);
        messages.value = [
            { severity: 'error', content: 'Add fail', id: messagesId++ },
        ];
    });
}

function setDataFromCase(id) {
    instance.get(`/scam/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("soc_token")}`
        }
    }).then(case_res => {
        userId.value = case_res.data.userId;
        phishingUrl.value = case_res.data.phishingUrl;
        phishingUrlEncode.value = case_res.data.phishingUrlEncode;
        threatsCategory.value = { name: case_res.data.threatsCategory };
        incidentClass.value = { name: case_res.data.incidentClass };
        counterMeasure.value = { name: case_res.data.countermeasure };
        incidentTime.value = case_res.data.incidentTime,
            department.value = { name: case_res.data.department };
        departmentNumber.value = { name: case_res.data.departmentNumber };
        issuedBy.value = { name: case_res.data.issuedBy };
        issuedYear.value = case_res.data.issuedYear;
        investigateSolution.value = { name: case_res.data.investigateSolution };
        scamType.value = { name: case_res.data.scamType };
        scamSystemName.value = case_res.data.scamSystemName;
        scamDepartmentName.value = case_res.data.scamDepartmentName;
        region.value = { name: case_res.data.region };
        province.value = { name: case_res.data.province };
        domain.value = case_res.data.domain;
        majorAffiliation.value = case_res.data.majorAffiliation;
        subAffiliation.value = case_res.data.subAffiliation;
        scamCaseNo.value = case_res.data.scamCaseNo;
        scamTypeNumber.value = { name: case_res.data.scamTypeNumber };
        informedChannel.value = { name: case_res.data.informedChanel };
        informerName.value = case_res.data.informerName;



    }).catch(case_err => {
        console.log("case_err");
        console.log(case_err);
    });
}

</script>
<style></style>